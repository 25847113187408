<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p
          v-if="userData.member_type_id == 2"
          class="user-name font-weight-bolder mb-0"
        >
          {{ userData.business_name }}
        </p>
        <p
          v-else
          class="user-name font-weight-bolder mb-0"
        >
          {{ userData.surname }}, {{ userData.other_names }}
        </p>
        <div class="d-flex">
          <p
            v-if="userData.registration_number"
            class="my-auto mr-1"
          >
            {{ userData.registration_number }}
          </p>
          <b-badge
            pill
            :variant="`${resolveContentVariant(userData.status)}`"
            class="text-capitalize my-auto"
          >
            {{ userData.status }}
          </b-badge>
        </div>
      </div>
      <b-avatar
        v-if="userData.avatar"
        size="40"
        :src="userData.avatar"
        variant="light-primary"
      />
      <b-avatar
        v-else
        size="40"
        :src="userData.avatar"
        variant="light-primary"
      >
        <feather-icon
          v-if="!userData.first_name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'profile-view'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BBadge,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBadge,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('user')),
      avatarText,
    }
  },
  methods: {
    resolveContentVariant(status) {
      if (status === 'Approved') return 'success'
      if (status === 'Pending') return 'secondary'
      if (status === 'Denied') return 'danger'
      return 'primary'
    },
    logout() {
      this.$http.post('/membership/auth/logout')
        .then(() => {
          // Remove userData from localStorage
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('user')
          localStorage.removeItem('permissions')

          // Reset ability
          this.$ability.update(initialAbility)

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Logged Out',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Invalid credentials',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
